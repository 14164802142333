body {
  margin: 0;
}

html[data-theme='trxact'] {
  --global-body-bg-color: #f2f2f2;
  --global-font-family: 'Montserrat';
  --global-primary-color: #75975e;
}

html[data-theme='sahl'] {
  --global-body-bg-color: #f2f2f2;
  --global-font-family: "Open Sans", sans-serif;
  --global-primary-color: #4055FF;
}