body {
  background-color: var(--global-body-bg-color);
  font-family: var(--global-font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.align-next {
  display: flex;
  flex-direction: row;
}

.marginalize {
  margin: 20px;
}

.marginalize-cards {
  margin: 20px auto;
}

.rectangle {
  border: 1px solid gray;
  border-radius: 5px;
  padding: 20px;
  max-width: 200px;
  max-height: 200px;
}

.loading-circle {
  display: flex;
  position: relative;
  height: auto !important;
  top: 50%;
  left: 45%;
}

.mt-20 {
  margin-top: 20%;
}

.subtitle-color {
  color: #7C8DB5
}

.satisfied_condition{
  color: green;
}

.non_satisfied_condition{
  color: red;
}

.primary-color {
  color: var(--global-primary-color);
}

.form-control {
  border-radius: 8px !important;
  border-color: #EAEAEA !important;
}

.btn-primary,
.btn-primary:hover {
  background-color: var(--global-primary-color);
  border-radius: 8px;
  border-color: var(--global-primary-color);
}

.main-box {
  background-color: #fff !important;
  border-radius: 10px !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 8px !important;
  border-color: #EAEAEA !important;
  color: gray !important;
}

@media screen and (max-width: 768px) {
  .date-picker {
    margin-bottom: 40px !important;
  }
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}

.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-iconSeparator {
  display: none !important;
}

.header-hover:hover {
  color: var(--global-primary-color);
}

.search-block:hover i {
  stroke: var(--global-primary-color) !important;
}

.quick-search-text {
  background-color: #F2F2F2;
  color: #706C6C;
}

.quick-search-text:hover {
  background-color: var(--global-primary-color);
  color: #fff;
}

.advanced-search-text {
  color: var(--global-primary-color);
  background-color: #fff;
  cursor: pointer;
  margin-top: 24px !important;
}

.vertical-line {
  border-left: 1px solid #F2F2F2;
  height: auto;
}

.search-icon {
  position: absolute;
  margin-top: 1.8%;
}

@media screen and (max-width: 991px) {
  .advanced-search-text {
    margin-top: 4px !important;
    padding-bottom: 14px;
  }

  .search-icon {
    position: absolute;
    margin-top: 0.9%;
  }
}

.logout-btn {
  color: #2E2E2E;
  font-weight: 600;
  font-size: larger;
}

.statistics-box {
  border: 1px solid #E6EDFF !important;
  border-radius: 10px !important;
}

.right-border {
  border-right: 1px solid #E6EDFF;
  height: auto;
}

.integration-step-color {
  color: #777777
}

.code-box {
  background-color: #F2F2F2;
  border-radius: 8px;
  color: var(--global-primary-color);
}

.copy-icon {
  stroke: #666666;
}

.copy-icon:hover {
  stroke: var(--global-primary-color);
}

.copy-icon-box {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0px;
}

.product-card {
  border: 1.5px solid #EAEAEA !important;
  border-radius: 10px !important;
}

.error-border {
  border-color: red !important;
}