:root {
    --navBg: #fff;
}

.navbar {
    background-color: var(--navBg);
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: 99999999 !important;
}

.sahl-logo {
    width: 80px;
    height: 40px;
}

.menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
}

.nav-menu {
    background-color: var(--navBg);
    height: auto;
    justify-content: center;
    top: 0;
    left: -100%;
    transition: 850ms;
}

@media screen and (min-width: 768px) {
    .nav-menu {
        height: 100vh !important;
    }
}

.nav-menu.active {
    left: 0;
    transition: 350ms;
    z-index: 99999999 !important;
}

.nav-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0 8px 16px;
    list-style: none;
    height: 60px;
    z-index: 99999999 !important;

}

.nav-text a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
}

.nav-menu-items {
    width: 100%;
    padding: 0;
    z-index: 99999999 !important;

}

.nav-text a:hover .link-title,
.is-active .link-title {
    color: #100F40 !important;
    font-weight: 800 !important;
}

.nav-text a:hover .link-icon,
.is-active .link-icon {
    color: var(--global-primary-color) !important;
    font-weight: bold !important;
    stroke: var(--global-primary-color);
}

.link-title {
    color: #666666;
    font-size: medium;
}

.nav-title {
    line-height: 15px;
}

.link-icon {
    stroke: #666666;
}

.title-color {
    color: #100F40
}